import routerOptions0 from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.2.1_eslint@9.15.0_jiti@2.4.2_d83d2cf8fa746259c39a6ad798c27398/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/campinginfo/civ2-nuxt-3/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}
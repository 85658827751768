function loadJs(url: string, attrs: any = null) {
  if (!import.meta.client) {
    return;
  }
  const tag = document.createElement('script');
  tag.defer = true;
  tag.src = url;
  tag.type = 'text/javascript';
  if (attrs) {
    for (const [key, value] of Object.entries(attrs)) {
      // @ts-check
      tag[key] = value;
    }
  }
  const script = document.getElementsByTagName('script')[0];
  script.parentNode?.insertBefore(tag, script);
}

function _loadConsentManager() {
  if (!import.meta.client) {
    return;
  }
  loadJs('/assets/js/consentmanager.js', {
    onload: function () {
      initializeVendors();
    },
  });
}

export function loadConsentManager() {
  if (!import.meta.client) {
    return;
  }
  setTimeout(() => {
    _loadConsentManager();
  }, 750);
}

function initializeVendors() {
  if (!import.meta.client) {
    return;
  }
  checkCustomConsents({
    s7: 'FACEBOOK',
    s46: 'HOTJAR',
    c53267: 'SNOWPLOW_LOGGED_IN',
    c69235: 'INSIDER',
  });

  checkIabConsents({
    907: ['AWIN', [1, 7]],
    755: ['GA', [1, 3, 4, 7, 9, 10]],
    42: ['TABOOLA', [1, 2, 3, 4, 5, 6, 7, 8, 10]],
    164: ['OUTBRAIN', [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]],
  });

  // @ts-check
  window.__cmp(
    'addEventListener',
    [
      'consent',
      () => {
        setTimeout(() => {
          // @ts-check
          window.__consentIsReady = true;
          // @ts-check
          if (window.onConsentReady !== undefined) {
            // @ts-check
            window.onConsentReady();
          }
        }, 100);
      },
      false,
    ],
    null,
  );
}

export function loadMoli(url: string) {
  // @ts-check
  if (window.__doLoadMoli) {
    loadJs(url);
  }
}

export function getCurrentURL(baseurl, route) {
  let url = decodeURIComponent(`${baseurl}${route.fullPath}`);
  if (url.startsWith('//localhost')) {
    url = `http:${url}`;
  }
  return url;
}

export function pushDataLayer(obj) {
  if (window === undefined) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
}

export function gtagReportConversion(eventName) {
  if (window === undefined) {
    return;
  }
  pushDataLayer({ event: eventName });
}

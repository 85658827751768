import payload_plugin_letbqHiLxv from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.6_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.27.4/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_yA8HsO8BCM from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.2.1_eslint@9.15.0_jiti@2.4.2_d83d2cf8fa746259c39a6ad798c27398/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_K5T21sSnbx from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.2.1_eslint@9.15.0_jiti@2.4.2_d83d2cf8fa746259c39a6ad798c27398/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iFW7RduWFW from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.2.1_eslint@9.15.0_jiti@2.4.2_d83d2cf8fa746259c39a6ad798c27398/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_CEVPFfO2sa from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.2.1_eslint@9.15.0_jiti@2.4.2_d83d2cf8fa746259c39a6ad798c27398/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QTe468JVU5 from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.2.1_eslint@9.15.0_jiti@2.4.2_d83d2cf8fa746259c39a6ad798c27398/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dSc8oyRVcb from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.2.1_eslint@9.15.0_jiti@2.4.2_d83d2cf8fa746259c39a6ad798c27398/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_glYHzdyTex from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.2.1_eslint@9.15.0_jiti@2.4.2_d83d2cf8fa746259c39a6ad798c27398/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xshEmu2KrM from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.6_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.27.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/builds/campinginfo/civ2-nuxt-3/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_JbwxIXsgos from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.9.3_db0@0.2.1_eslint@9.15.0_jiti@2.4.2_d83d2cf8fa746259c39a6ad798c27398/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_FlKELaFnJ2 from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_rollup@4.27.4_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_lRNnGmIl68 from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@9.15.0_jiti@2.4.2__magicast@0.3.5_ro_f47b43c67b8b62db63f892e2c5626f38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_mqSRaOS2uM from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@9.15.0_jiti@2.4.2__magicast@0.3.5_ro_f47b43c67b8b62db63f892e2c5626f38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_iFQ6aCuRJi from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@9.15.0_jiti@2.4.2__magicast@0.3.5_ro_f47b43c67b8b62db63f892e2c5626f38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _10_init_iIsdpEioc8 from "/builds/campinginfo/civ2-nuxt-3/app/plugins/10.init.ts";
import _15_translate_RrjibBgPvV from "/builds/campinginfo/civ2-nuxt-3/app/plugins/15.translate.ts";
import _20_auth_client_pJoWrzl57v from "/builds/campinginfo/civ2-nuxt-3/app/plugins/20.auth.client.ts";
import _30_device_client_uHUSkXBSCL from "/builds/campinginfo/civ2-nuxt-3/app/plugins/30.device.client.ts";
import _40_touch_client_OhhosWQGiK from "/builds/campinginfo/civ2-nuxt-3/app/plugins/40.touch.client.ts";
import _60_modal_w9X8qcXd7O from "/builds/campinginfo/civ2-nuxt-3/app/plugins/60.modal.ts";
import _70_observeVisiblity_7TLCm0a0B2 from "/builds/campinginfo/civ2-nuxt-3/app/plugins/70.observeVisiblity.ts";
import _80_loadExternalScripts_client_NSKtBKyfnl from "/builds/campinginfo/civ2-nuxt-3/app/plugins/80.loadExternalScripts.client.ts";
import _90_snowplow_client_5U5NloArNk from "/builds/campinginfo/civ2-nuxt-3/app/plugins/90.snowplow.client.ts";
import _95_sentry_client_S5S0L2vgip from "/builds/campinginfo/civ2-nuxt-3/app/plugins/95.sentry.client.ts";
import ssg_detect_fvzhXegcPh from "/builds/campinginfo/civ2-nuxt-3/node_modules/.pnpm/@nuxtjs+i18n@9.2.1_@vue+compiler-dom@3.5.13_eslint@9.15.0_jiti@2.4.2__magicast@0.3.5_ro_f47b43c67b8b62db63f892e2c5626f38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_letbqHiLxv,
  revive_payload_client_yA8HsO8BCM,
  unhead_K5T21sSnbx,
  router_iFW7RduWFW,
  payload_client_CEVPFfO2sa,
  navigation_repaint_client_QTe468JVU5,
  check_outdated_build_client_dSc8oyRVcb,
  chunk_reload_client_glYHzdyTex,
  plugin_vue3_xshEmu2KrM,
  components_plugin_zlvi6dcIsi,
  prefetch_client_JbwxIXsgos,
  plugin_client_FlKELaFnJ2,
  switch_locale_path_ssr_lRNnGmIl68,
  route_locale_detect_mqSRaOS2uM,
  i18n_iFQ6aCuRJi,
  _10_init_iIsdpEioc8,
  _15_translate_RrjibBgPvV,
  _20_auth_client_pJoWrzl57v,
  _30_device_client_uHUSkXBSCL,
  _40_touch_client_OhhosWQGiK,
  _60_modal_w9X8qcXd7O,
  _70_observeVisiblity_7TLCm0a0B2,
  _80_loadExternalScripts_client_NSKtBKyfnl,
  _90_snowplow_client_5U5NloArNk,
  _95_sentry_client_S5S0L2vgip,
  ssg_detect_fvzhXegcPh
]
import type { H3Event } from 'h3';
import { setHeader } from 'h3';

const DEFAULT_CACHE_DURATION = 3600 * 4;
const CACHE_DURATION_HOME = 3600 * 4;

export default defineNuxtRouteMiddleware((to) => {
  if (!import.meta.server) {
    return;
  }

  const routeBaseName = useRouteBaseName();
  const requestEvent = useRequestEvent();
  const routeName = routeBaseName(to);

  if (!routeName) {
    return;
  }

  if (routeName === 'index') {
    setHeader(requestEvent as H3Event, 'Cache-Control', `public, max-age=${CACHE_DURATION_HOME}`);
  } else {
    setHeader(requestEvent as H3Event, 'Cache-Control', `public, max-age=${DEFAULT_CACHE_DURATION}`);
  }
});

import { defineStore, acceptHMRUpdate } from 'pinia';

import { isEmpty } from 'ramda';
import type { Consents, UserProfile, ProfileLanguage, ProfileUpdatedCallback } from '@/types';

// Stores
import { useLoadingStore } from '@/stores/loading';

import { COOKIEDOMAIN, USER_LOGIN_DEFAULT_COOKIE_LIFETIME, USER_LOGIN_TOKEN } from '@/utils/constants';

export const useUserStore = defineStore('user', () => {
  /********************
   * COMPOSITIONS      *
   ********************/
  const userService = useUserService();
  const { setStatusError, setStatusLoading, setStatusSuccess } = useLoadingStore();

  const expiresNormal: Date = new Date();
  expiresNormal.setDate(expiresNormal.getDate() + USER_LOGIN_DEFAULT_COOKIE_LIFETIME);
  const expiresPersistent: Date = new Date();
  let loginPersistent: boolean = false;
  expiresPersistent.setDate(expiresPersistent.getDate() + 365 * 10);
  let userLoginTokenCookie = useCookie<string | null>(USER_LOGIN_TOKEN, { sameSite: 'strict', secure: true, expires: expiresNormal, ...(COOKIEDOMAIN && { domain: COOKIEDOMAIN }) });
  let userLang = 'en';

  /********************
   * REFS & VARS       *
   ********************/
  const user = reactive<{
    languages: ProfileLanguage[] | null;
    profile: UserProfile | null;
    token: UserProfile['key'] | null;
    consents: Consents;
  }>({
    languages: [],
    profile: null,
    token: null,
    consents: {},
  });

  const favoritePending = ref<string | boolean>(false);
  const profileUpdatedCallbacks: ProfileUpdatedCallback[] = [];
  const profileUpdatedCallbacksOnce: ProfileUpdatedCallback[] = [];
  const insiderUid = ref<string | null>(null);
  const insiderLoaded = ref<boolean>(false);
  let insiderTimeout: number | null = null;

  const favorites = computed(() => {
    return user.profile?.favorites || [];
  });

  const loggedIn = computed(() => {
    return user.token !== null;
  });

  const consentsWithSnoplowLoggedIn = computed(() => {
    if (isEmpty(user.consents)) {
      return false;
    }
    return !!user.consents.SNOWPLOW_LOGGED_IN;
  });

  const userIdWhenConsented = computed((): string | null => {
    if (!loggedIn.value || !consentsWithSnoplowLoggedIn.value) {
      return null;
    }
    return user.profile?.uid || null;
  });

  const hasInsiderConsent = computed(() => {
    return !!user.consents.INSIDER;
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function init(lang: string) {
    userLang = lang;
    const token = userLoginTokenCookie.value || null;

    if (token) {
      user.token = token;
      loadUser(lang);
    } else {
      deleteUser();
    }
  }

  function subscribeProfileUpdated(callback: ProfileUpdatedCallback, once: boolean = false) {
    if (once) {
      _subscribeProfileUpdatedOnce(callback);
    } else {
      _subscribeProfileUpdated(callback);
    }
  }

  function _subscribeProfileUpdated(callback: ProfileUpdatedCallback) {
    if (profileUpdatedCallbacks.includes(callback)) {
      return;
    }
    profileUpdatedCallbacks.push(callback);
  }

  function _subscribeProfileUpdatedOnce(callback: ProfileUpdatedCallback) {
    if (profileUpdatedCallbacksOnce.includes(callback)) {
      return;
    }
    profileUpdatedCallbacksOnce.push(callback);
  }

  function onProfileUpdated() {
    profileUpdatedCallbacks.forEach((func: ProfileUpdatedCallback) => {
      func(userIdWhenConsented.value);
    });
    profileUpdatedCallbacksOnce.forEach((func: ProfileUpdatedCallback) => {
      func(userIdWhenConsented.value);
    });
    while (profileUpdatedCallbacksOnce.length) {
      profileUpdatedCallbacksOnce.pop();
    }
  }

  function checkInsiderLoaded(tryNum: number = 0) {
    if (!hasInsiderConsent.value || !import.meta.client) {
      return;
    }

    const insiderObj = window.Insider || null;

    if (!insiderObj && tryNum >= 50) {
      clearTimeout(insiderTimeout as number);
      insiderTimeout = null;
      insiderLoaded.value = true;
      return;
    }

    if (!insiderObj && !insiderTimeout) {
      insiderTimeout = window.setTimeout(() => {
        insiderTimeout = null;
        checkInsiderLoaded(tryNum + 1);
      }, 50);
      return;
    }

    if (insiderObj) {
      insiderUid.value = insiderObj.getUserId();
    }

    insiderLoaded.value = true;
    if (loggedIn.value) {
      userService.updateInsider(userLang, insiderUid.value);
    }
  }

  function setConsents(consents: Consents) {
    user.consents = consents;
    onProfileUpdated();
    checkInsiderLoaded();
  }

  function loadUser(lang: string) {
    if (user.token) {
      userService
        .getProfile(lang)
        .then((response) => {
          user.profile = response;
          onProfileUpdated();
        })
        .catch(() => {
          logout();
        });
    }
  }

  function saveUser(payload: { user: UserProfile }) {
    user.profile = payload.user;
    onProfileUpdated();
  }

  async function saveToken(payload: { token: string; persistence: boolean }) {
    user.token = payload.token;

    if (loginPersistent !== payload.persistence) {
      loginPersistent = payload.persistence;
      userLoginTokenCookie = useCookie<string | null>(USER_LOGIN_TOKEN, { sameSite: 'strict', secure: true, expires: loginPersistent ? expiresPersistent : expiresNormal, ...(COOKIEDOMAIN && { domain: COOKIEDOMAIN }) });
    }

    userLoginTokenCookie.value = payload.token;
  }

  function deleteUser() {
    userLoginTokenCookie.value = null;
    user.languages = null;
    user.profile = null;
    user.token = null;
    onProfileUpdated();
  }

  function setHasPassword() {
    user.profile.auth.has_password = true;
  }

  async function login({
    lang,
    email,
    password,
    remember,
  }: {
    lang: string;
    email: string;
    password: string;
    remember: boolean;
  }) {
    setStatusLoading('login');
    try {
      const response = await userService.login(lang, { email, password, insider_uid: insiderUid.value });
      if (response) {
        saveToken({
          token: response.key,
          persistence: remember,
        });
        saveUser({ user: response });
        setStatusSuccess('login');
      }
      return response;
    } catch (error) {
      setStatusError({ name: 'login', error });
      deleteUser();
      throw error;
    }
  }

  async function logout() {
    setStatusLoading('logout');

    try {
      const response = await userService.logout();
      deleteUser();
      setStatusSuccess('logout');
      return response;
    } catch (error) {
      setStatusError({ name: 'logout', error });
      throw error;
    }
  }

  async function addFavorite(slug: string) {
    setStatusLoading('addFavorite');

    if (!user.profile) {
      throw new Error('no user');
    }

    try {
      const response = await userService.toggleFavorite({ slug, toggle: 1 });
      user.profile.favorites = response.favorites;
      setStatusSuccess('addFavorite');
      return response;
    } catch (error) {
      setStatusError({ name: 'addFavorite', error });
      throw error;
    }
  }

  async function removeFavorite(slug: string) {
    setStatusLoading('removeFavorite');

    if (!user.profile) {
      throw new Error('no user');
    }

    try {
      const response = await userService.toggleFavorite({ slug, toggle: 0 });
      user.profile.favorites = response.favorites;
      setStatusSuccess('removeFavorite');
      return response;
    } catch (error) {
      setStatusError({ name: 'removeFavorite', error });
      throw error;
    }
  }

  async function getUserReviews({ lang, params }: { lang: string; params: any }) {
    setStatusLoading('getUserReviews');

    try {
      const response = await userService.getReviews(lang, params);
      setStatusSuccess('getUserReviews');
      return response;
    } catch (error) {
      setStatusError({ name: 'getUserReviews', error });
      throw error;
    }
  }

  async function getUserMedia({ lang, params }: { lang: string; params: any }) {
    setStatusLoading('getUserMedia');

    try {
      const response = await userService.getMedia(lang, params);
      setStatusSuccess('getUserMedia');
      return response;
    } catch (error) {
      setStatusError({ name: 'getUserMedia', error });
      throw error;
    }
  }

  async function getUserMediaForCampsite({ lang, slug }: { lang: string; slug: string }) {
    setStatusLoading('getUserMediaForCampsite');

    try {
      const response = await userService.getMediaForCampsite(lang, slug);
      setStatusSuccess('getUserMediaForCampsite');
      return response;
    } catch (error) {
      setStatusError({ name: 'getUserMediaForCampsite', error });
      throw error;
    }
  }

  async function setProfilePicture({ lang, data }: { lang: string; data: any }) {
    setStatusLoading('setProfilePicture');

    try {
      const response = await userService.setProfilePicture(lang, data);
      setStatusSuccess('setProfilePicture');
      user.profile = { ...user.profile, images: response };
      return response;
    } catch (error) {
      setStatusError({ name: 'setProfilePicture', error });
      throw error;
    }
  }

  async function setProfile({ lang, data }: { lang: string; data: any }) {
    setStatusLoading('setProfile');

    try {
      const response = await userService.setProfile(lang, data);
      setStatusSuccess('setProfile');
      user.profile = { ...user.profile, ...response };
      onProfileUpdated();
      return response;
    } catch (error) {
      setStatusError({ name: 'setProfile', error });
      throw error;
    }
  }

  async function getProfileLanguages({ lang }: { lang: string }) {
    if (user.languages?.length) {
      return;
    }

    setStatusLoading('getProfileLanguages');

    try {
      const response = await userService.getProfileLanguages(lang);
      user.languages = response;
      setStatusSuccess('getProfileLanguages');
      return response;
    } catch (error) {
      setStatusError({ name: 'getProfileLanguages', error });
      throw error;
    }
  }

  return {
    addFavorite,
    deleteUser,
    favoritePending,
    favorites,
    getProfileLanguages,
    getUserMedia,
    getUserMediaForCampsite,
    getUserReviews,
    hasInsiderConsent,
    init,
    insiderUid,
    loadUser,
    loggedIn,
    login,
    logout,
    removeFavorite,
    saveToken,
    saveUser,
    setConsents,
    setHasPassword,
    setProfile,
    setProfilePicture,
    subscribeProfileUpdated,
    user,
    userIdWhenConsented,
  };
});

export type UserStore = ReturnType<typeof useUserStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}

// ofetch is the internally used fetch library in nuxt, see: https://github.com/unjs/ofetch
import type { $Fetch, FetchContext } from 'ofetch';

type ApiVersion = null | number;

let client: $Fetch | undefined;

export default function useFetchService({
  base = '',
  rewrite = true,
  version = null,
  attachClient = true,
}: {
  base?: string;
  rewrite?: boolean;
  version?: ApiVersion;
  attachClient?: boolean;
}) {
  /********************
   * COMPOSITIONS      *
   ********************/
  const backendStore = useBackendStore();
  const runtimeConfig = useRuntimeConfig();
  const userStore = useUserStore();

  /********************
   * REFS & VARS       *
   ********************/
  const globalVersion = version;
  const root = `${runtimeConfig.public.apiUrl}`
    .trim()
    .replace(/^(https?:|)\/\//, '')
    .replace(/\/$/, '');
  const baseUrl = root.includes('localhost') && !base.startsWith('https://') ? `//${root}/${base}/` : base;

  /********************
   * FUNCTIONS         *
   ********************/
  function onRequest({ options }: FetchContext) {
    const authToken = userStore?.user?.token;
    if (authToken) {
      options.headers = options.headers || {};
      if (options.headers.delete === undefined) {
        options.headers['Authorization'] = `Token ${authToken}`;
      } else {
        options.headers.set('Authorization', `Token ${authToken}`);
      }
    } else {
      if (options.headers.delete === undefined) {
        delete options.headers['Authorization'];
      } else {
        options.headers.delete('Authorization');
      }
    }
  }

  function onResponseError({ response }: FetchContext) {
    if (response?.status === 401 && response?._data?.code === 'authentication_failed') {
      userStore?.deleteUser();
      if (import.meta.client) {
        window.location.reload();
      }
    }
  }

  function getConfig(config: any, lang: string | null, version: ApiVersion = null) {
    const headers = config.headers || {};
    const query = config.params || {};
    if (lang !== null) {
      query.lang = lang;
    }

    let v = version;
    if (v === null) {
      v = globalVersion;
    }

    if (v !== undefined && v !== null && v !== '' && headers) {
      headers['X-API-Version'] = v.toString();
    }

    if (!query.client && attachClient) {
      query.client = 'web';
    }

    return {
      headers,
      query,
    };
  }

  function getBaseUrl() {
    if (rewrite && backendStore?.url) {
      return backendStore?.url;
    } else {
      return baseUrl;
    }
  }

  async function get(
    url: string,
    lang: string | null,
    config: any = {},
    version: ApiVersion = null,
    useBaseUrl: boolean = true,
  ) {
    if (useBaseUrl) {
      url = `${getBaseUrl()}${url}`;
    }
    const { headers, query } = getConfig(config, lang, version);
    return await client?.(url, { headers, query });
  }

  async function getPlain(url: string) {
    return await client?.(`${getBaseUrl()}${url}`, {
      onRequest({ options }) {
        delete options.headers?.Authorization;
      },
    });
  }

  async function patch(url: string, lang: string | null, data = {}, config = {}, version = null, useBaseUrl = true) {
    if (useBaseUrl) {
      url = `${getBaseUrl()}${url}`;
    }
    const { headers, query } = getConfig(config, lang, version);
    return await client?.(url, { method: 'PATCH', headers, query, body: data });
  }

  async function post<T>(url: string, lang: string | null, data = {}, config = {}, version = null, useBaseUrl = true) {
    if (useBaseUrl) {
      url = `${getBaseUrl()}${url}`;
    }
    const { headers, query } = getConfig(config, lang, version);
    return await client?.<T>(url, { method: 'POST', headers, query, body: data });
  }

  async function put(url: string, lang: string | null, data = {}, config = {}, version = null, useBaseUrl = true) {
    if (useBaseUrl) {
      url = `${getBaseUrl()}${url}`;
    }
    const { headers, query } = getConfig(config, lang, version);
    return await client?.(url, { method: 'PUT', headers, query, body: data });
  }

  if (!client) {
    client = $fetch.create({ onRequest, onResponseError }); // onResponse
  }

  return {
    get,
    getPlain,
    patch,
    post,
    put,
  };
}

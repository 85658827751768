import { createGettext } from 'vue3-gettext';
import availableLangs from '@/locales/availableLanguages';

export default function gettext() {
  return createGettext({
    availableLanguages: availableLangs.reduce((accumulator, currentValue) => {
      accumulator[currentValue.code] = currentValue.name;
      return accumulator;
    }, {}),
    silent: true,
    defaultLanguage: 'de',
    translations: { de: { stub: 'stub' } },
    provideDirective: false,
    provideComponent: false,
  });
}

export const $gettext = key => key;
export const $pgettext = (ctx, key) => ({ ctx, key });

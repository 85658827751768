import {
  newTracker,
  trackPageView,
  trackStructEvent,
  setCustomUrl,
  setOptOutCookie,
  setUserId,
  type BrowserTracker,
} from '@snowplow/browser-tracker';
import { PerformanceNavigationTimingPlugin } from '@snowplow/browser-plugin-performance-navigation-timing';
import { LinkClickTrackingPlugin, trackLinkClick } from '@snowplow/browser-plugin-link-click-tracking';
import { AdTrackingPlugin, trackAdClick, trackAdImpression } from '@snowplow/browser-plugin-ad-tracking';
import { setEcommerceUser, setPageType as setSnowplowPageType, trackPromotionClick, trackPromotionView, trackProductListClick, trackProductListView, trackAddToCart, trackProductView, SnowplowEcommercePlugin } from '@snowplow/browser-plugin-snowplow-ecommerce';
import type { Page } from '@snowplow/browser-plugin-snowplow-ecommerce';
import type {
  AdImpressionEvent,
  CommonEventProperties,
  StructuredEvent,
} from '@snowplow/tracker-core';
import { COOKIEDOMAIN } from '@/utils/constants';

export default defineNuxtPlugin({
  name: 'snowplow',
  parallel: true,
  setup() {
    let contentTimeout: null | ReturnType<typeof setTimeout> = null;
    let pageViewTimeout: null | ReturnType<typeof setTimeout> = null;
    let pageViewReady: boolean = false;

    const nuxtApp = useNuxtApp();
    const route = useRoute();
    const runtimeConfig = useRuntimeConfig();
    const loadingStore = useLoadingStore(nuxtApp.$pinia);
    const userStore = useUserStore();

    const OPT_OUT_COOKIE_NAME: string = '_sp_optout';
    const optOutCookie = useCookie(OPT_OUT_COOKIE_NAME);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let pageType: string | null = null;
    let pageLanguage: string = 'de';

    const pageViewRelevantLoadingStates = [
      'getBootstrap',
      'getCampsite',
      'getContent',
      'getHomeData',
      'getSearchResults',
      'getMapSearchResults',
      'getLocationSearchResults',
      'login',
      'logout',
    ];

    const tracker: BrowserTracker = newTracker('sp', runtimeConfig.public.snowplowUrl, {
      appId: 'civ2-frontend-v3',
      platform: 'web',
      cookieDomain: runtimeConfig.public.snowplowCookieDomain as string,
      cookieName: '_sp_',
      cookieSameSite: 'Lax',
      respectDoNotTrack: true,
      eventMethod: 'post',
      postPath: runtimeConfig.public.snowplowPostPath as string,
      preservePageViewIdForUrl: 'pathnameAndSearch',
      contexts: {
        browser: true,
        session: true,
        webPage: true,
      },
      plugins: [LinkClickTrackingPlugin(), PerformanceNavigationTimingPlugin(), AdTrackingPlugin(), SnowplowEcommercePlugin()],
    }) as BrowserTracker;

    setOptOutCookie(OPT_OUT_COOKIE_NAME);

    userStore.subscribeProfileUpdated((uid: string | null): void => {
      const domainUserId = tracker.getDomainUserId();
      if (uid) {
        setEcommerceUser({ id: uid, is_guest: false });
      } else {
        setEcommerceUser({ id: domainUserId, is_guest: true });
      }
      setUserId(uid || domainUserId);
    });

    function setPageViewPending() {
      pageViewReady = false;
    }

    function hasAdSlots() {
      return document.getElementsByClassName('ci-ad-slot').length > 0;
    }

    async function _trackAdImpression(event: AdImpressionEvent & CommonEventProperties, _trackers?: Array<string>) {
      if (!pageViewReady) {
        setTimeout(() => {
          _trackAdImpression(event, _trackers);
        }, 100);
        return;
      }

      return trackAdImpression(event, _trackers);
    }

    async function _trackStructEvent(event: StructuredEvent & CommonEventProperties, trackers?: Array<string>) {
      if (!pageViewReady) {
        setTimeout(() => {
          _trackStructEvent(event, trackers);
        }, 100);
        return;
      }
      return trackStructEvent(event, trackers);
    }

    function trackDataLayer() {
      const user = {};
      const userProfile = userStore.user.profile;

      if (userStore.user.token && !userProfile) {
        userStore.subscribeProfileUpdated(trackDataLayer, true);
        return;
      }

      if (userProfile && userProfile.uid) {
        user.uid = userProfile.uid;
        user.language = pageLanguage;
        if (userProfile.first_name) {
          user.first_name = userProfile.first_name;
        }
        if (userProfile.last_name) {
          user.last_name = userProfile.last_name;
        }
        if (userProfile.email) {
          user.email = userProfile.email;
        }
      }
      pushDataLayer({
        event: 'ci_page_view',
        page_has_ads: hasAdSlots(),
        user,
      });
    }

    function _trackPageView() {
      if (!import.meta.client) {
        return;
      }

      if (pageViewRelevantLoadingStates.some(value => loadingStore.state.loading.has(value))) {
        if (contentTimeout) {
          clearTimeout(contentTimeout);
        }
        contentTimeout = setTimeout(() => {
          _trackPageView();
        }, 100);
      } else {
        nextTick(async () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          pageViewTimeout && clearTimeout(pageViewTimeout);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          contentTimeout && clearTimeout(contentTimeout);

          const headerTags = await nuxtApp.vueApp._context.provides.usehead.resolveTags();
          const pageTitle = headerTags?.find((tag: any) => tag.tag === 'title')?.textContent;
          const url = getCurrentURL(runtimeConfig.public.baseUrl, route);

          pageViewTimeout = setTimeout(() => {
            setCustomUrl(url);
            trackPageView({
              title: pageTitle || document.title,
            });
            pageViewReady = true;
            // window.gtag?.('set', 'page_path', decodeURIComponent(route.fullPath));
            trackDataLayer();
          }, 50);
        });
      }
    }

    function optOutActive(): boolean {
      return !!optOutCookie.value;
    }

    function optOut(): void {
      const expires: Date = new Date();
      expires.setDate(expires.getDate() + 365);
      const optOutCookie = useCookie<string>(OPT_OUT_COOKIE_NAME, { sameSite: 'strict', secure: true, expires, ...(COOKIEDOMAIN && { domain: COOKIEDOMAIN }) });
      optOutCookie.value = '1';
    }

    function optIn(): void {
      const optOutCookie = useCookie(OPT_OUT_COOKIE_NAME, { sameSite: 'strict', secure: true, ...(COOKIEDOMAIN && { domain: COOKIEDOMAIN }) });
      optOutCookie.value = null;
    }

    function setPageType(context: Page, trackers?: Array<string>): void {
      pageType = context.type;
      pageLanguage = context.language || pageLanguage;
      return setSnowplowPageType(context, trackers);
    }

    return {
      provide: {
        optIn,
        optOut,
        optOutActive,
        setCustomUrl,
        setPageViewPending,
        setPageType,
        trackAdClick,
        trackAdImpression: _trackAdImpression,
        trackAddToCart,
        trackLinkClick,
        trackPageView: _trackPageView,
        trackProductListClick,
        trackProductListView,
        trackProductView,
        trackPromotionClick,
        trackPromotionView,
        trackStructEvent: _trackStructEvent,
      },
    };
  },
});

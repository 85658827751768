import type { Pinia } from 'pinia';

import { USER_COOKIE_LANGAUGE } from '@/utils/constants';

export default defineNuxtPlugin(async () => {
  const { $i18n, $pinia } = useNuxtApp();

  /********************
   * COMPOSITIONS      *
   ********************/
  const getRouteBaseName = useRouteBaseName();
  const localePath = useLocalePath();
  const route = useRoute();
  const router = useRouter();

  const appStore = useAppStore($pinia as Pinia);
  const backendStore = useBackendStore($pinia as Pinia);
  const userStore = useUserStore($pinia as Pinia);

  if (route.query.backend) {
    backendStore.setBackendUrl({ url: route.query.backend as string });
  }

  const userLangCookie = useCookie<string>(USER_COOKIE_LANGAUGE, { sameSite: 'strict', secure: true });
  if (!userLangCookie.value || userLangCookie.value !== $i18n.locale.value) {
    userLangCookie.value = $i18n.locale.value;
  }

  // call bootstrap data
  if (import.meta.server) {
    try {
      await appStore.getBootstrap({ lang: $i18n.locale.value });
    } catch (_e) {
      appStore.backendUnavailable = true;
    }
  }

  // add router hooks
  if (import.meta.client) {
    router.beforeEach((to, from, next) => {
      const baseRouteName = getRouteBaseName(to);
      const authRoutes = [RBN_IMAGE_UPLOAD, RBN_VIDEO_UPLOAD];

      const routeStore = useRouteStore($pinia as Pinia);

      if (!baseRouteName?.startsWith('campsite-identifier') && from.fullPath !== to.fullPath) {
        const searchStore = useSearchStore($pinia as Pinia);
        searchStore.location = null;
      }

      routeStore.previousRoute = from;

      if (baseRouteName && authRoutes.includes(baseRouteName) && !userStore.loggedIn) {
        return next(localePath({ name: RBN_LOGIN, query: { redirect: to.fullPath, ...to.query }, params: to.params }));
      } else {
        return next();
      }
    });

    router.afterEach((to, from) => {
      const loadingStore = useLoadingStore($pinia as Pinia);
      const routeStore = useRouteStore($pinia as Pinia);

      loadingStore.resetLoadingState();
      routeStore.setLastSearchRoute(to);

      if (!from || (from && from.path !== to?.path)) {
        appStore.isInitialRequest = false;

        if (to.fullPath !== from.fullPath) {
          backendStore.resetFetchStatus();
        }
      }
    });
  }
});

import { loadMoli } from '@/utils/h5v';

export default defineNuxtPlugin({
  name: 'loadExternalScripts',
  parallel: true,
  setup() {
    const runtimeConfig = useRuntimeConfig();
    const userStore = useUserStore();

    let consentReadyCalled = false;

    // @ts-check
    window.onConsentReady = () => {
      onConsentReady();
    };

    // @ts-check
    if (window.__consentIsReady) {
      onConsentReady();
    }

    function onConsentReady() {
      if (consentReadyCalled) {
        return;
      }
      consentReadyCalled = true;
      if (window.__consents) {
        userStore.setConsents(window.__consents);
      }

      if (runtimeConfig.public.appVersion !== 'local') {
        loadMoli(runtimeConfig.public.h5vMoliUrl as string);
      }
    }
  },
});
